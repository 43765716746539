import { useEffect, useRef, useState, MutableRefObject } from "react";

interface ClickOutsideHookProps {
	show: boolean;
	isOutSite: boolean;
	NodeRef: MutableRefObject<null | HTMLDivElement>;
	setShow: React.Dispatch<React.SetStateAction<boolean>>;
	setIsOutSite:React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = {
	callback?: () => void;
	defaultValue?: boolean;
};

export default function useClickOutside(props: Props): ClickOutsideHookProps {
	const [show, setShow] = useState(props.defaultValue || false);
	const [isOutSite, setIsOutSite] = useState(false);
	const NodeRef = useRef<null | HTMLDivElement>(null);

	function handleClickOutside(e: MouseEvent): void {
		if (
			NodeRef &&
			NodeRef.current &&
			!NodeRef.current.contains(e.target as Node)
		) {
			setIsOutSite(true);
			setShow(false);
			props.callback && props.callback();
		}
	}

	useEffect(() => {
		window.addEventListener("click", handleClickOutside);

		return () => {
			window.removeEventListener("click", handleClickOutside);
		};
	}, [NodeRef]);

	return {
		show,
		NodeRef,
		isOutSite,
		setIsOutSite,
		setShow,
	};
}
