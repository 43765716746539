import React from "react";
import { cn } from "@/utils/utils";

export default function Skeleton({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={cn(
        "animate-pulse border-[1px] border-gray-200 bg-gray-200 w-[100px] h-[100px]",
        className
      )}
    >
      {children}
    </div>
  );
}
