import { User } from "@/types/Auth.type";

export const DOMAIN = "https://namperfume.net";
export const SITE_NAME = "namperfume";

const Website = {
	ID: "nperp-features",
	FLOAT_BUTTON: {
		LIVE: 5,
		CONTACT: 3,
		BOXCHAT: 4,
	},
	TIMEOUT: {
		GROUP: 10 * 1000,
		VARIANTS: 30 * 1000,
		// GROUP: 0,
		// VARIANTS: 0,
	},

	LINK: {
		PAGE: {
			LOGIN: "/account/login",
			REWARD_VIEW: "/account/membership",
			COUPON: "/account/mycoupon",
			ORDER_PAGE: "/account/orders",
			CAU_HOI_THUONG_GAP: "/pages/cau-hoi-thuong-gap",
			CACH_THUC_MUA_HANG: "/pages/cach-thuc-mua-hang",
			HUONG_DAN_DAT_HANG: "/pages/huong-dan-dat-hang",
			PHUONG_THUC_VAN_CHUYEN: "/pages/phuong-thuc-van-chuyen",
			PHUONG_THUC_THANH_TOAN: "/pages/phuong-thuc-thanh-toan",
			THEO_DOI_DON_HANG: "/pages/theo-doi-don-hang",
			CHINH_SACH_GIA_CA: "/pages/chinh-sach-gia-ca",
			CHINH_SACH_DOI_TRA: "/pages/chinh-sach-doi-tra",
			CHINH_SACH_BAO_MAT: "/pages/chinh-sach-bao-mat",
			TUYEN_DUNG: "/pages/tuyen-dung",
			LIEN_HE: "/pages/lien-he",
			ABOUT_US: "/pages/about-us",
			NMAGAZINE: "/pages/nmagazine",
			BRAND: "/pages/thuong-hieu-a-z",
			BRAND_FAVORITE: "/pages/thuong-hieu",
			WISHLIST: "/pages/wishlist",
			REGISTER: "/account/register",
			PROFILE: "/account",
			ADDRESS: "/account/addresses",
			ORDER_PUBLIC: "/orderstatus",
			HE_THONG_CUA_HANG: "/pages/he-thong-cua-hang",
			HANG_THANH_VIEN_CUA_TOI: "/account/membership",
			GIOI_THIEU_THE_THANH_VIEN: "/pages/about-membership",
			DIEU_KHOAN_THE_THANH_VIEN: "/pages/about-membership/terms-conditions",
			HOI_DAP_THE_THANH_VIEN: "/pages/about-membership/qa",
			BLOG: "/blogs",
			THUONG_HIEU_NUOC_HOA: "/blogs/thuong-hieu-nuoc-hoa",
			XEM_GAN_DAY: "/pages/xem-gan-day",
			TIM_KIEM: "/search",
			DIEU_KHOAN_DICH_VU: "/pages/dieu-khoan-dich-vu",
			CHINH_SACH_DOI_TRA_1: "/pages/chinh-sach-doi-tra-1",
			HOI_DAP_VE_NUOC_HOA: "/pages/hoi-dap-ve-nuoc-hoa",
			HOI_DAP_VE_HANG_HOA: "/pages/hoi-dap-ve-hang-hoa",
			GIA_CA_VA_THANH_TOAN: "/pages/gia-ca-va-thanh-toan",
			WE_ARE_NAMPERFUME: "/pages/we-are-namperfume",
			CART: "/cart",
			COLLECTION: "/collections",
		},
		MEDIA: {
			FACEBOOK: "https://www.facebook.com/namperfumee",
			TIKTOK: "https://www.tiktok.com/@namperfume.net",
			INSTAGRAM: "https://www.instagram.com/namperfumee",
			YOUTUBE: "https://www.youtube.com/@namperfumeTV",
			CONTACT: "tel:19000129",
		},
	},
	ADDRESS: [
		"420/6 Lê Văn Sỹ, Phường 14, Quận 3, TP. Hồ Chí Minh",
		"1379-1381 Đường 3/2, Phường 16, Quận 11, TP. Hồ Chí Minh",
		"45-47 CMT8, Phường Bến Thành, Quận 1, TP. Hồ Chí Minh",
		"8 Nguyễn Gia Trí, Phường 25,Quận Bình Thạnh, TP. Hồ Chí Minh",
		"366A18 Phan Văn Trị, Phường 05, Quận Gò Vấp, TP. Hồ Chí Minh",
		"123-125 Võ Thị Sáu, Phường Thống Nhất, TP. Biên Hòa, T.Đồng Nai",
		"86 Mậu Thân, Phường An Hòa, Quận Ninh Kiều, Cần Thơ",
	],
	TAX: "http://online.gov.vn/Home/WebDetails/88969",
};

export default Website;
