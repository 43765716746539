import React from "react";
import { PopupErrorIcon, SuccessIcon } from "../icons";

type Props = {
	type?: "success" | "error";
	children?: React.ReactNode;
};
export default function ToastMessage({ type = "success", children }: Props) {
	return (
		<div className="w-[375px] flex justify-center items-center flex-col gap-1">
			{type === "success" ? <SuccessIcon /> : <PopupErrorIcon />}
			{children && children}
		</div>
	);
}
