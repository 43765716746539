"use client";
import Money from "@/components/ui/Money";
import { ProductJson } from "@/types/Product.type";
import React, { use, useEffect, useMemo, useState } from "react";
import ProductPercentSale from "./ProductPercentSale";
import { cn } from "@/utils/utils";
import { CalcPricePromotionJson, PricePromotionJson } from "@/types/Cart.type";

type Props = {
	productVariant: ProductJson;
	productPrice?: number;
	className?: string;
	classNamePrice?: string;
	classNamePriceCompare?: string;
	classNameDiscount?: string;
	showDiscount?: boolean;
	priceFinal?: number;
	text?: string;
	showRoundBrackets?: boolean;
	quantity?: number;
};
export default function ProductSinglePrice({
	productVariant,
	className,
	showDiscount,
	text,
	showRoundBrackets,
	classNamePrice,
	priceFinal: priceFinals,
	classNamePriceCompare,
	classNameDiscount,
	quantity,
}: Props) {
	const [pricePromotion, setPricePromotion] = useState<CalcPricePromotionJson>(
		[]
	);

	const pricePreivew = useMemo(() => {
		let result: PricePromotionJson = {
			discount: 0,
			item_quantity: 0,
			price_final: 0,
			price_Unit: 0,
			product_id: 0,
		};
		if (!productVariant) return result;
		if (
			productVariant.pricePreview &&
			productVariant.pricePreview.discount > 0
		) {
			result = productVariant.pricePreview;
		}
		return result;
	}, [productVariant]);

	const priceCompare = useMemo(() => {
		const priceCompare = productVariant.compare_at_price;
		const price = productVariant.price;
		let result = 0;

		if (priceCompare > 0 && priceCompare >= price) {
			result = priceCompare;
		}

		if (priceCompare <= 0 && pricePreivew.discount > 0) {
			result = price;
		}
		if (quantity) {
			result = result * quantity;
		}
		return result;
	}, [productVariant, pricePreivew]);

	const priceFinal = useMemo(() => {
		let result = productVariant.price;

		if (priceFinals) {
			result = priceFinals;
		} else {
			if (pricePreivew.discount > 0) {
				result = pricePreivew.price_final;
			}
		}

		return result;
	}, [pricePromotion, productVariant, pricePreivew, priceFinals]);

	// useEffect(() => {
	// 	handleGetPricePromtion();
	// }, [productVariant.id]);

	return (
		<div className={cn("flex md:flex-col gap-[10px]", className)}>
			{priceFinal > 0 && (
				<>
					{priceCompare > 0 && priceCompare > priceFinal && (
						<p className=" leading-none flex items-center">
							<Money
								className={cn(
									"line-through text-sm text-gray-500",
									classNamePriceCompare
								)}
								value={priceCompare}
							/>
							<span>
								{showDiscount && (
									<ProductPercentSale
										quantity={quantity}
										text={text}
										productVariant={productVariant}
										pricePreviewPromotion={pricePreivew}
										priceFinal={priceFinals}
										className={cn("ml-1", classNameDiscount)}
										showRoundBrackets={showRoundBrackets}
									/>
								)}
							</span>
						</p>
					)}

					<Money
						className={cn(
							"block font-bold text-lg text-red-500",
							classNamePrice
						)}
						value={priceFinal}
					/>
				</>
			)}
		</div>
	);
}
