"use client";
import React from "react";

import PopupV2 from "./PopupV2";

type Props = {
	open: boolean;
	isMask?: boolean;
	// classNameWrapper?: string;
	classNameBody?: string;
	// classNameContent?: string;
	// classNameButton?: string;
	children: React.ReactNode;
	destroyOnClose?: boolean;
	onClose: () => void;
	closeOnMark?: boolean;
	isDrawerMobile?:boolean
};

const PopupDefault = ({
	open,
	onClose,
	classNameBody = "",
	children,
	closeOnMark = true,
	isDrawerMobile
}: Props) => {

	return (
		<>
			{/* <CSSTransition
				in={show}
				timeout={300}
				classNames={"modal-bg"}
				unmountOnExit>
				<div
					className={cn(
						"fixed top-0 left-0 bottom-0 right-0 bg-[#0000006d] flex justify-center items-center z-[2002]",
						classNameWrapper
					)}>
					<div
						className={cn(
							`relative bg-white rounded-[8px] shadow-lg md:max-w-[720px] lg:max-w-[810px]`,
							classNameBody
						)}>
						<button
							type="button"
							className={cn(
								`absolute top-[16px] right-[16px] w-[24px] h-[24px] flex justify-center items-center z-[1002]`,
								classNameButton
							)}
							onClick={onClose}>
							<CloseIcon />
						</button>
						<div
							ref={NodeRef}
							className={cn(
								"p-[24px] sm:p-[32px] w-full h-full",
								classNameContent
							)}>
						</div>
					</div>
				</div>
			</CSSTransition> */}
			<PopupV2
				open={open}
				onOpenChange={() => { onClose?.() }}
				classNameContent={classNameBody}
				closeOnMark={closeOnMark}
				 classNameDrawerMobile={classNameBody}
				isDrawerMobile={isDrawerMobile}
			>
				{children}

			</PopupV2>
		</>
	);
};

export default PopupDefault;
