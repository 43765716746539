"use client";

import { cn } from "@/utils/utils";
import React, { useMemo } from "react";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

import { ProductJson } from "@/types/Product.type";
import { PricePromotionJson } from "@/types/Cart.type";

export default function ProductPercentSale({
  text,
  className,
  showRoundBrackets = true,
  productVariant,
  pricePreviewPromotion,
  priceFinal: priceFinals,
  quantity = 1
}: {
  text?: string;
  className?: string;
  showRoundBrackets?: boolean;
  productVariant: ProductJson;
  pricePreviewPromotion?: PricePromotionJson;
  priceFinal?: number;
  quantity? : number
}) {
  const { t } = useTranslationApi(["product_price_discount" , "product_save"]);

  const discountPercent = useMemo(() => {
    const priceCompare = productVariant.compare_at_price * quantity;
    const price = productVariant.price * quantity
    let result = 0;
    if (priceCompare > 0) {
      if (pricePreviewPromotion && pricePreviewPromotion.discount > 0) {
      
        const priceFinal = pricePreviewPromotion.price_final;
        result = ((priceCompare - priceFinal) / priceCompare) * 100;
   
      } else if (priceFinals) {
        const priceFinal = priceFinals;
        result = ((priceCompare - priceFinal) / priceCompare) * 100;
      } else if (priceCompare > price) {
        result = ((priceCompare - price) / priceCompare) * 100;
      }
    } else {
      if (priceFinals) {
        const priceFinal = priceFinals;

        result = ((price - priceFinal) / price) * 100;
      } else {
        if (pricePreviewPromotion && pricePreviewPromotion.discount > 0) {

          const priceFinal = pricePreviewPromotion.price_final;
          result = ((price - priceFinal) / price) * 100;
        }
      }
    }

    return result;
  }, [
    productVariant.compare_at_price,
    productVariant.price,
    productVariant.promotions,
    pricePreviewPromotion,
    priceFinals,
  ]);

  return (
    <>
      {discountPercent > 0 && (
        <span className={cn("text-sm text-gray-500 leading-[1.6]", className)}>
          {showRoundBrackets && "("}
          {typeof text === "string" ? `${text}` : `${t("product_save")} `}
          {discountPercent.toFixed(0)}%{showRoundBrackets && ")"}
        </span>
      )}
    </>
  );
}
