export default function QuantityIcon({
	type,
	disable,
}: {
	type: "minus" | "plus";
	disable?: boolean;
}) {
	if (type === "plus") {
		if (typeof disable !== "undefined" && disable === false) {
			return (
				<span>
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M0.5 8C0.5 6.10025 0.501062 4.72573 0.641988 3.67754C0.78098 2.64373 1.04772 2.00253 1.52513 1.52513C2.00253 1.04772 2.64373 0.78098 3.67754 0.641988C4.72573 0.501062 6.10025 0.5 8 0.5H10C11.8998 0.5 13.2743 0.501062 14.3225 0.641988C15.3563 0.78098 15.9975 1.04772 16.4749 1.52513C16.9523 2.00253 17.219 2.64373 17.358 3.67754C17.4989 4.72573 17.5 6.10025 17.5 8V10C17.5 11.8998 17.4989 13.2743 17.358 14.3225C17.219 15.3563 16.9523 15.9975 16.4749 16.4749C15.9975 16.9523 15.3563 17.219 14.3225 17.358C13.2743 17.4989 11.8998 17.5 10 17.5H8C6.10025 17.5 4.72573 17.4989 3.67754 17.358C2.64373 17.219 2.00253 16.9523 1.52513 16.4749C1.04772 15.9975 0.78098 15.3563 0.641988 14.3225C0.501062 13.2743 0.5 11.8998 0.5 10V8Z"
							stroke="#D8D7D8"
						/>
						<path d="M9 5L9 13" stroke="#D8D7D8" stroke-linejoin="round" />
						<path d="M13 9L5 9" stroke="#D8D7D8" stroke-linejoin="round" />
					</svg>
				</span>
			);
		}
		return (
			<span>
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M0.5 8C0.5 6.10025 0.501062 4.72573 0.641988 3.67754C0.78098 2.64373 1.04772 2.00253 1.52513 1.52513C2.00253 1.04772 2.64373 0.78098 3.67754 0.641988C4.72573 0.501062 6.10025 0.5 8 0.5H10C11.8998 0.5 13.2743 0.501062 14.3225 0.641988C15.3563 0.78098 15.9975 1.04772 16.4749 1.52513C16.9523 2.00253 17.219 2.64373 17.358 3.67754C17.4989 4.72573 17.5 6.10025 17.5 8V10C17.5 11.8998 17.4989 13.2743 17.358 14.3225C17.219 15.3563 16.9523 15.9975 16.4749 16.4749C15.9975 16.9523 15.3563 17.219 14.3225 17.358C13.2743 17.4989 11.8998 17.5 10 17.5H8C6.10025 17.5 4.72573 17.4989 3.67754 17.358C2.64373 17.219 2.00253 16.9523 1.52513 16.4749C1.04772 15.9975 0.78098 15.3563 0.641988 14.3225C0.501062 13.2743 0.5 11.8998 0.5 10V8Z"
						stroke="#3A393A"
					/>
					<path d="M9 5L9 13" stroke="#3A393A" strokeLinejoin="round" />
					<path d="M13 9L5 9" stroke="#3A393A" strokeLinejoin="round" />
				</svg>
			</span>
		);
	}

	return (
		<span>
			<svg
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M0.5 8C0.5 6.10025 0.501062 4.72573 0.641988 3.67754C0.78098 2.64373 1.04772 2.00253 1.52513 1.52513C2.00253 1.04772 2.64373 0.78098 3.67754 0.641988C4.72573 0.501062 6.10025 0.5 8 0.5H10C11.8998 0.5 13.2743 0.501062 14.3225 0.641988C15.3563 0.78098 15.9975 1.04772 16.4749 1.52513C16.9523 2.00253 17.219 2.64373 17.358 3.67754C17.4989 4.72573 17.5 6.10025 17.5 8V10C17.5 11.8998 17.4989 13.2743 17.358 14.3225C17.219 15.3563 16.9523 15.9975 16.4749 16.4749C15.9975 16.9523 15.3563 17.219 14.3225 17.358C13.2743 17.4989 11.8998 17.5 10 17.5H8C6.10025 17.5 4.72573 17.4989 3.67754 17.358C2.64373 17.219 2.00253 16.9523 1.52513 16.4749C1.04772 15.9975 0.78098 15.3563 0.641988 14.3225C0.501062 13.2743 0.5 11.8998 0.5 10V8Z"
					stroke="#3A393A"
				/>
				<path d="M13 9L5 9" stroke="#3A393A" strokeLinejoin="round" />
			</svg>
		</span>
	);
}
