import { CalcPricePromotionJson, PricePromotionJson } from "@/types/Cart.type";
import { PromotionJson } from "@/types/Promotion.type";
import { ProductActualQuantity, ProductJson, ProductRelated } from "@/types/Product.type";
import Cart from "@/common/constants/cart";
import { VariantsFromTags } from "@/lib/hooks/useVariants";

export function getPricePreviewProduct(
	product: ProductJson,
	quantity: number = 1
): PricePromotionJson | null {
	const promotionActive = product.promotions
		? product.promotions.filter((pro) => pro.status)
		: [];

	if (promotionActive.length === 0) {
		return null;
	}

	const promtion = promotionActive[0];
	let value = {
		discount: 0,
		item_quantity: quantity,
		price_final: product.price * quantity,
		price_Unit: 1,
		product_id: product.id,
	};

	if (promtion.discount_value > 0) {
		let discount = 0;
		switch (promtion.discount_type) {
			case "percent":
				discount = product.price * quantity * (promtion.discount_value / 100);
				break;
			case "value":
				discount = promtion.discount_value;
				break;
			default:
				2;
				break;
		}

		value.discount = Math.round(discount);
		value.price_final = Math.round(value.price_final - discount);
	}

	return value;
}

export function getPromotionValid(promotions: PromotionJson[] , currentTime?: number) {
	const time = currentTime ? currentTime : new Date().getTime() / 1000
	const datas = promotions.filter((item) => {
		const type = getTypePromotion(item,time);
		return item.status && type === Cart.PROMOTION_TYPE.PROCESSING;
	});

	return datas;
}

const getTypePromotion = (item: PromotionJson, timeNow: number): number => {
	const startDate = Number(item?.start_date);
	const endDate = Number(item?.end_date);
	if (isNaN(startDate) || isNaN(endDate)) {
		// Handle invalid date values if necessary
		throw new Error("Invalid promotion date");
	}
	if (startDate > timeNow) {
		return Cart.PROMOTION_TYPE.PENDING;
	}

	if ((startDate <= timeNow && endDate >= timeNow) || endDate === 0) {
		return Cart.PROMOTION_TYPE.PROCESSING;
	}

	if (endDate < timeNow) {
		return Cart.PROMOTION_TYPE.DONE;
	}

	return 0; // Default case, though ideally it should not reach here
};

export function mapActualQuantity(
	arr: ProductJson[],
	listActualQuantity: ProductActualQuantity[]
) {
	const newVariantsFull = arr.map((variant) => {
		if (listActualQuantity.length > 0) {
			const findItem = listActualQuantity.find(
				(item) => item.product_id === variant.id
			);
			const quan = findItem?.actual_quantity
			return {
				...variant,
				actuals: findItem
					? {
							actual_quantity: findItem.actual_quantity,
							product_id: findItem.product_id,
							quantity: findItem.quantity,
					  }
					: null,
				quantity: findItem ?  findItem.actual_quantity : variant.quantity   ,
			};
		}

		return variant;
	});

	return newVariantsFull;
}

export function mapPromotionPrice(
	variants: ProductJson[],
	caclPricePreview: CalcPricePromotionJson
) {
	const newVariants: ProductJson[] = variants.map((v) => {
		const findResult =
			caclPricePreview.find((i) => i.product_id === v.id) || null;
		return { ...v, pricePreview: findResult };
	});

	return newVariants;
}

 
export function converGroupToGroupVariant(
    group: ProductRelated[],
    variants: ProductJson[]
  ) {
    let variantGrops: VariantsFromTags[] = [];

    group.forEach((gro) => {
      const itemsIds = gro.items;
      const list: ProductJson[] = [];
      itemsIds.forEach((id:number) => {
        const product = variants.find((item) => item.id === id);
        if (product) {
          list.push(product);
        }
      });

      if (!list.length) return;
      variantGrops.push({
        data: list,
        name: gro.name,
      });
    });

    return variantGrops;
  }