"use client"
import Button from "@/components/ui/Button";
import { ProductJson } from "@/types/Product.type";
import React from "react";
import { useAppDispatch } from "@/redux/hook";
import { setProductSendMail } from "@/redux/features/ShopSlice";

export default function ProductBtnSendMail({
  className,
  productVariantActive,
}: {
  productVariantActive: ProductJson;
  className?: string;
}) {
  const dispatch = useAppDispatch()
  function setIsOpenModalProductOut(pro : ProductJson){
    dispatch(setProductSendMail(pro))
  }
  return (
    <>
      <Button
        label="Gửi Email khi có hàng"
        onClick={() => setIsOpenModalProductOut(productVariantActive)}
        className={className}
      />
    </>
  );
}
