import Helper from "@/utils/helper";
import { cn } from "@/utils/utils";
import React from "react";

type Props = {
	value: number;
	className?: string;
	minus?: string;
};
export default function Money({ value, className, minus }: Props) {
	return (
		<strong className={cn("font-[400]", className)}>
			{minus && minus}
			{Helper.moneyFormat(value)}đ
		</strong>
	);
}
