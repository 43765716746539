"use client";
import {
	FilterWishlist,
	WishlistAddEditJson,
	WishlistJson,
} from "@/types/Wishlist.type";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	addWishlistPublic,
	addWishlistUser,
	getWishlistPublic,
	getWishlistUser,
	removeWishlistPublic,
	removeWishlistUser,
} from "../api/shop/wishlist";
import { useSession } from "next-auth/react";
import useBrowerId from "./useBrowerId";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import ToastMessage from "./../../components/ui/ToastMessage";
import { getListBrandPublic } from "../api/shop/brand";
import { BrandJson } from "@/types/Brand.type";
import useCustomer from "./useCustomer";

export default function useWishlistV2(model?: WishlistAddEditJson) {
	const QUERY_KEY = "wishlist";


	const { data: user } = useCustomer()

	// const { data, status } = useSession();
	const { data: browserId } = useBrowerId();
	const isUser = !!user

	const [wishlists, setWishlists] = useState<WishlistJson[]>([]);
	const [brands, setBrands] = useState<BrandJson[]>([]);

	const {
		data: wishlistRes,
		isLoading,
		error,
	} = useQuery({
		queryKey: ["wishlist"],
		queryFn: async () => {
			try {
				const filter: FilterWishlist = isUser
					? { sort_by: "date_created", sort_type: "DESC" }
					: {
							browser_id: browserId,
							sort_by: "date_created",
							sort_type: "DESC",
					  };

				const res = isUser
					? await getWishlistUser(filter)
					: await getWishlistPublic(filter);

				const collectionJson = res.data.items;

				return collectionJson;
			} catch (error) {
				return [];
			}
		},
		staleTime: Infinity,
		enabled: isUser,
		retry: 1,
	});

	const wishlist = wishlistRes || [];

	// const { data: brandList } = useQuery({
	// 	queryKey: ["brandlist"],
	// 	queryFn: async () => {
	// 		try {
	// 			const collection = await getListBrandPublic({
	// 				page: 1,
	// 				list_brand_id: wishlists
	// 					.filter((i) => i.item_type === 2)
	// 					.map((w) => w.item_id)
	// 					.join(","),
	// 			});

	// 			return collection.data.items;
	// 		} catch (error) {
	// 		} finally {
	// 		}
	// 	},
	// 	staleTime: 5,
	// 	enabled: wishlists.length > 0,
	// });

	const queryClient = useQueryClient();

	const addWishList = async (data: WishlistAddEditJson) => {
		let postData = {
			...data,
			browser_id: browserId,
			time: Math.random(),
		};
		try {
			const resAction = isUser
				? await addWishlistUser(postData)
				: await addWishlistPublic(postData);
		} catch (error) {
			toast(
				<ToastMessage type="error">
					<p>Thêm yêu thích thất bại !</p>
				</ToastMessage>
			);
		}
	};

	const removeWishList = async (data: WishlistAddEditJson): Promise<any> => {
		// setBrands(brands.filter((i) => i.id !== data.item_id));
		const res = isUser
			? await removeWishlistUser(data.id || 0)
			: await removeWishlistPublic(data);
	};

	const handleWishListActions = async (data: WishlistAddEditJson) => {
		try {
			const itemExited = wishlists.find(
				(item) => item.item_id === data.item_id
			);
			itemExited ? await removeWishList(itemExited) : await addWishList(data);
			queryClient.invalidateQueries({ queryKey: ["wishlist"] }).then(() => {
				// !itemExited &&
				// 	queryClient.invalidateQueries({ queryKey: ["brandlist"] });
			});
		} catch (error) {
			// throw Error(JSON.stringify(error));
		}
	};
	/////////////////////////

	// const getDataBrands = async () => {
	// 	try {
	// 		const listWishlistBrand = wishlists.filter((i) => i.item_type === 2);
	// 		if (listWishlistBrand.length > 0) {
	// 			const collection = await getListBrandPublic({
	// 				page: 1,
	// 				list_brand_id: listWishlistBrand.map((i) => i.item_id).join(","),
	// 			});
	// 			const brandSorted = collection.data.items.sort((a, b) =>
	// 				a.title
	// 					.charAt(0)
	// 					.toLowerCase()
	// 					.localeCompare(b.title.charAt(0).toLowerCase())
	// 			);
	// 			setBrands(brandSorted);
	// 		}
	// 	} catch (error) {
	// 		setBrands([]);
	// 	} finally {
	// 	}
	// };

	const exists = useMemo(() => {
		if (!model) return false;
		return (
			(wishlist &&
				wishlist.length > 0 &&
				wishlist.some(
					(i: WishlistJson) => i.item_id === model.item_id && model.item_id > 0
				)) ||
			false
		);
	}, [wishlist, model]);

	useEffect(() => {
		if (wishlistRes) {
			setWishlists(wishlistRes);
		}
	}, [wishlistRes]);

	// useEffect(() => {
	// 	if (wishlists.length > 0) {
	// 		getDataBrands();
	// 	}
	// }, [wishlists]);

	return {
		wishlist: wishlists,
		brandlist: brands,
		isLoading,
		error,
		handleWishListActions,
		exists,
	};
}
