"use client";
import React from "react";
import { PopupErrorIcon } from "@/components/icons";
import Button from "@/components/ui/Button";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import { ButtonProps } from "@/components/ui/Button";
import { cn } from "@/utils/utils";

type Props = {
	buttonConfirmProps: ButtonProps & {
		show: boolean;
	};
	buttonCancelProps: ButtonProps & {
		show: boolean;
	};
	message?: string | React.ReactNode;
	className?: string;
};

const translate = {
	product_notice: "product_notice",
	no: "no",
	yes: "yes",
};

export default function CartUIConfirmPopup({
	buttonConfirmProps,
	buttonCancelProps,
	message,
	className,
}: Props) {
	const { t } = useTranslationApi(Object.values(translate));
	return (
		<div
			className={cn(
				"h-fit flex flex-col gap-4 justify-between items-center",
				className
			)}>
			<PopupErrorIcon />
			<p className="font-bold text-gray-500">{t(translate.product_notice)}</p>
			{message && message}
			<div className="flex justify-between gap-4 w-full">
				{buttonCancelProps.show && (
					<Button
						mode="dark-outline"
						label={t(translate.no)}
						{...buttonCancelProps}
					/>
				)}
				{buttonConfirmProps.show && (
					<Button
						mode="main"
						label={t(translate.yes)}
						{...buttonConfirmProps}
					/>
				)}
			</div>
		</div>
	);
}
