import React from 'react'

export default function PolygonIcon() {
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.7637 0.622488L11.7637 19.4395L0.65816 7.86746L11.7637 0.622488Z" fill="#767576"  />
    </svg>

  )
}


