"use client";
import { useEffect, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { getMultiSetting } from "../api/shop/setting";
import Helper from "@/utils/helper";
import {
	getDataFromLocalStorage,
	selectDataLanguage,
	setDataLanguage,
} from "@/redux/features/ShopSlice";
import {
	DataLanguageCache,
	DataLanguageCacheJson,
} from "@/common/types/SiteLanguage.type";
import SettingApi from "@/common/constants/setting";
import Local from "@/common/constants/local";

const useTranslationApi = (keys: string[]) => {
	const dispatch = useAppDispatch();
	const siteLanguage = useAppSelector((state) => state.ShopReducer.language);
	const dataLanguageCache = useAppSelector(
		(state) => state.ShopReducer.dataLanguage
	);
	const dataLocal = getDataFromLocalStorage<DataLanguageCache>(
		Local.cookies.language
	);

	const [isClient, setIsClient] = useState(false);

	const dataCache = dataLocal?.data || dataLanguageCache.data;
	const spaceCache = dataLanguageCache.space;

	const [loading, setLoading] = useState(false);

	const getDataTranslate = async () => {
		// const conditionFilter
		const keyNotExited = keys
			.filter((k) =>
				typeof dataCache === "object"
					? k.length > 0 && !dataCache.hasOwnProperty(k)
						? true
						: false
					: true
			)
			.map((k) => k.trim());
		if (keyNotExited.length > 0) {
			setLoading(true);

			const keysStr = Helper.removeCharAtString(keyNotExited.join(","));

			try {
				const res = await getMultiSetting(
					keysStr,
					{
						lang: siteLanguage.lang,
					},
					{ timeout: 10000 }
				);
				let data = {} as DataLanguageCacheJson;
				const listData = res.data.items;
				if (listData.length > 0) {
					data = listData.reduce((acc: DataLanguageCacheJson, crr) => {
						if (crr.value && crr.group === "LANGUAGE") {
							acc[crr.key] = crr.value;
						}

						return acc;
					}, {});
				} else {
					data = keyNotExited.reduce(
						(acc: DataLanguageCacheJson, crr: string) => {
							acc[crr.trim()] = crr.trim();

							return acc;
						},
						{}
					);
				}
				dispatch(
					setDataLanguage({
						data: data,
						space: keyNotExited,
						lang: siteLanguage.lang,
					})
				);
			} catch (error) {
			} finally {
				setLoading(false);
			}
		}
	};

	const unitKeyTranslate = (key: string) => {
		if (typeof key === "string") {
			return key.trim();
		}
		return key;
	};

	const viewTranslate = useCallback(
		(key: string) => {
			const k = unitKeyTranslate(key);

			if (loading) {
				return "...";
			}
			if (k in dataCache) {
				return dataCache[k];
			} else {
				return k;
			}
		},
		[siteLanguage, dataLanguageCache, loading, isClient]
	);

	useEffect(() => {
		getDataTranslate();
	}, [siteLanguage.lang]);

	// useEffect(() => {
	// 	setIsClient(true);
	// }, []);

	return { t: viewTranslate };
};

export default useTranslationApi;
