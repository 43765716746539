import {
  getSettingDealthom,
} from "@/redux/features/PromotionSlice";
import { useAppSelector } from "@/redux/hook";
import { ProductJson } from "@/types/Product.type";
import { PromotionGroupType, PromotionJson } from "@/types/Promotion.type";
import { getPromotionValid } from "@/utils/product";
import useTimeServer from "./useTimeServer";

export default function useDealthom(product: ProductJson) {
  const promotionCouponItems = product.promotions;
  const settingDealthom = useAppSelector(getSettingDealthom);
  const { data: time_server } = useTimeServer();
  const promotionsActive = getPromotionValid(
    promotionCouponItems,
    time_server?.data.time_server || new Date().getTime() / 1000
  );

  const promotionsHaveConfig: PromotionJson[] = promotionsActive.map((data) => {
    return {
      ...data,
      config: settingDealthom
        ? settingDealthom.find((setting) =>
            setting.promotion_id.includes(data.campaign_info.id)
          )
        : undefined,
    };
  });
  return {
    promotionsHaveConfig,
  };
}
