import Helper from "@/utils/helper";
import BaseApi from "@/common/api/BaseApi";
import {
  FilterWishlist,
  WishListCollectionJson,
  WishlistAddEditJson,
  WishlistJson,
} from "@/types/Wishlist.type";
import { callApiV2 } from "@/lib/config/Api";

async function getWishlistBrowserId() {
  const res = await callApiV2<string>(
    `/wishlists/public/browserid
    `,
    "post"
  );

  return res;
}

//public
async function getWishlistPublic(filters: FilterWishlist) {
  const apiParams = Helper.convertFilterToParams({
    ...filters,
    // type: "product",
  });

  const res = callApiV2<WishListCollectionJson>(
    `/wishlists/public${apiParams}`,
    "get"
  );

  return res;
}

async function addWishlistPublic(data: WishlistAddEditJson) {
  let dataProps: any = { ...data };

  let postData = {
    ...dataProps,
  };
  const res = await callApiV2<WishlistJson>(`/wishlists/public`, "post", {
    data: postData,
  });

  return res;
}

async function removeWishlistPublic(data: WishlistAddEditJson) {
  let postData = {
    browser_id: data.browser_id,
  };

  const res = await callApiV2<any>(`/wishlists/public/${data.id}`, "put", {
    data: postData,
  });

  return res;
}

//logged
async function getWishlistUser(filters: FilterWishlist) {
  const apiParams = Helper.convertFilterToParams({
    ...filters,
    // type: "product",
  });

  const res = await callApiV2<WishListCollectionJson>(
    `/wishlists${apiParams}`,
    "get"
  );

  return res;
}

async function addWishlistUser(data: WishlistAddEditJson) {
  let dataProps: any = { ...data };

  let postData = {
    ...dataProps,
  };
  const res = await callApiV2<WishlistJson>(`/wishlists`, "post", {
    data: postData,
  });

  return res;
}

async function removeWishlistUser(id: number) {
  const res = await callApiV2<any>(`/wishlists/${id}`, "put");

  return res;
}

export {
  getWishlistBrowserId,
  getWishlistPublic,
  addWishlistPublic,
  removeWishlistPublic,
  getWishlistUser,
  addWishlistUser,
  removeWishlistUser,
};
