import React, { useEffect, useMemo, useState } from "react";
import { ProductJson } from "@/types/Product.type";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/Tooltip";
import PolygonIcon from "@/components/icons/PolygonIcon";
import useClickOutside from "./../../../lib/hooks/useClickOutSide";
import { cn } from "@/utils/utils";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import { setPopupId } from "@/redux/features/ShopSlice";
import Cart from "@/common/constants/cart";
import { setCartGlobalErrors } from "@/redux/features/CartSlice";
import { Errors } from "@/common/constants/errors";
import PopupV2 from "@/components/PopupV2";
import { PopupErrorIcon } from "@/components/icons";
import CartUIConfirmPopup from "@/app/cart/_components/shopCart/shopCartPopup/CartUIConfirmPopup";
import QuantityIcon from "@/components/icons/QuantityIcon";
export type QuantityError = {
	type: "out" | "insuff";
	quantity: number;
};
type Props = {
	quantity: number;
	product: ProductJson;
	onHand?: "default" | "cart";
	className?: string;
	Zindex?: number;
	isCheckInCart?: boolean;
	onSubQuantity: () => void;
	onAddQuantity: () => void;
};

export default function QuantityBtn({
	quantity,
	product,
	className,
	Zindex = 5002,
	onHand = "default",
	isCheckInCart,
	onSubQuantity,
	onAddQuantity,
}: // onQuantityError,
Props) {
	const { t } = useTranslationApi(
		Object.values([
			"product_quantity",
			"product_please_contact_hotline",
			"product_if_buy_over",
		])
	);

	const {
		NodeRef,
		setShow: setShowToolTip,
		show: showToolTip,
	} = useClickOutside({
		defaultValue: true,
	});

	const device = useAppSelector((state) => state.ShopReducer.device);

	const dispatch = useAppDispatch();
	const cartDetail = useAppSelector((state) => state.CartReducer.cartDetail);

	const [error, setError] = useState({
		open: false,
		erros: [],
	});

	const isOutOfStock = product.quantity === 0;
	const handlerSubQuantity = () => {
		if (quantity > 1) {
			onSubQuantity();
		}
	};

	const handlerAddQuantity = () => {
		if (quantity < product.limit_sale && quantity < product.quantity) {
			onAddQuantity();
		} else {
			if (onHand === "cart" && product.quantity - quantity === 1) {
				onAddQuantity();
			}
			return;
			dispatch(setCartGlobalErrors([Errors.error_item_quantity_invalid]));
			dispatch(setPopupId(Cart.POPUP.cart_error_popup));
		}
	};

	// const handleSetErrorQuantity = () => {
	// 	if (quantity >= product.limit_sale) {
	// 		setError({ quantity: quantity, type: "insuff" });
	// 		onQuantityError &&
	// 			onQuantityError({ quantity: quantity, type: "insuff" });
	// 		setError({ quantity: quantity, type: "insuff" });
	// 		return;
	// 	}
	// 	if (quantity >= product.quantity) {
	// 		onQuantityError && onQuantityError({ quantity: quantity, type: "out" });
	// 		setError({ quantity: quantity, type: "out" });
	// 	}
	// };

	// process

	const isDisableInsuff = useMemo(() => {
		if (quantity >= product.limit_sale) {
			return true;
		}
		return false;
	}, [product, quantity]);

	const errorQuatity: QuantityError | null = useMemo(() => {
		if (isCheckInCart) {
			const itemThisInCart = cartDetail?.details?.data?.find((item) => {
				return item.product_id === product.id;
			});
			if (
				itemThisInCart &&
				quantity >= product.quantity - itemThisInCart.item_quantity
			) {
				return {
					type: "out",
					quantity: product.quantity - itemThisInCart.item_quantity,
				};
			} else if (quantity >= product.quantity) {
				return {
					type: "out",
					quantity: product.quantity,
				};
			} else {
				return null;
			}
		}
		// else if (quantity > product.quantity) {
		//   return {
		//     type: "out",
		//     quantity: product.quantity,
		//   };
		// }
		else {
			return null;
		}
	}, [quantity, product, cartDetail?.details?.data]);

	return (
		<>
			<div
				className={cn(
					"text-gray-500 flex items-center justify-between w-full flex-wrap",
					className
				)}>
				<p className="text-base leading-[1.6]">{t("product_quantity")}:</p>
				<div className="flex items-center gap-[8px]">
					<button
						className=" w-[24px] p-[3px] h-[24px] text-gray-400 outline-none flex items-center justify-center "
						onClick={(e: any) => {
							e.stopPropagation();
							handlerSubQuantity();
						}}
						disabled={isOutOfStock || quantity === 1}>
						{/* - */}
						<QuantityIcon type="minus"></QuantityIcon>
					</button>
					<p className="text-gray-500 flex justify-center items-end text-base font-bold w-[12px]">
						{quantity}
					</p>
					{isDisableInsuff ? (
						<>
							{device === "mobile" ? (
								<button
									className={`w-[24px] p-[3px] h-[24px] text-gray-400 flex items-center justify-center disabled:text-gray-300 ${
										product.quantity <= quantity ? "cursor-not-allowed" : ""
									}`}
									disabled={errorQuatity?.type === "out"}
									onClick={(e: any) => {
										if (errorQuatity?.type === "out") return;
										setError((prev) => ({ ...prev, open: true }));
									}}>
									{/* + */}
									<QuantityIcon type="plus"></QuantityIcon>
								</button>
							) : (
								<Tooltip open={showToolTip} placement="top-end">
									<TooltipTrigger
										onMouseEnter={() => setShowToolTip(true)}
										onMouseLeave={() => setShowToolTip(false)}>
										<button
											className=" disabled:text-gray-300 w-[18px] h-[18px] text-gray-400  outline-none flex items-center justify-center "
											disabled>
											{/* + */}
											<QuantityIcon type="plus" disable={true}></QuantityIcon>
										</button>
									</TooltipTrigger>
									<TooltipContent
										style={{ zIndex: Zindex }}
										ref={NodeRef}
										className="Tooltip">
										<div className=" relative right-2">
											<div className=" w-[150px] h-[60px] p-2 rounded bg-[#767576] relative top-2">
												<p className=" text-center text-xs text-white">
													{t("product_please_contact_hotline")} <br />{" "}
													<span className=" font-semibold">1900 0129</span>{" "}
													{t("product_if_buy_over")} {product.limit_sale}{" "}
													{t("product")}
												</p>
											</div>
											<div className=" flex justify-end ">
												<PolygonIcon />
											</div>
										</div>
									</TooltipContent>
								</Tooltip>
							)}
						</>
					) : (
						<button
							className={`w-[24px] p-[3px] h-[24px] text-gray-400 outline-none flex items-center justify-center disabled:text-gray-300 ${
								product.quantity <= quantity ? "cursor-not-allowed" : ""
							}`}
							// disabled={errorQuatity?.type === "out"}
							onClick={(e: any) => {
								// if (errorQuatity?.type === "out") return;
								e.stopPropagation();
								handlerAddQuantity();
							}}>
							<QuantityIcon type="plus"></QuantityIcon>
						</button>
					)}
				</div>
			</div>

			<PopupV2
				classNameContent="md:w-[380px] max-w-[calc(100vw-32px)]"
				isDrawerMobile={false}
				open={error.open}
				onOpenChange={() => {
					setError((prev) => ({ ...prev, open: false }));
				}}>
				<CartUIConfirmPopup
					buttonConfirmProps={{
						show: true,
						className: "hidden",
					}}
					buttonCancelProps={{
						show: true,
						className: "hidden",
					}}
					message={
						<div className="text-center">
							<p className="text-base">
								Vui lòng liên hệ Hotline{" "}
								<strong>
									<a href="tel:1900 0129">1900 0129</a>
								</strong>{" "}
								nếu như mua nhiều hơn <strong>{product.limit_sale}</strong> sản
								phẩm.
							</p>{" "}
						</div>
					}
				/>
			</PopupV2>
		</>
	);
}
